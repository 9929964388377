import { template as template_32630bf00e3744b88f11d10d1b4ee956 } from "@ember/template-compiler";
const FKText = template_32630bf00e3744b88f11d10d1b4ee956(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
