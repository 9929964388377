import { template as template_4d6ad0ae1a074db597970b26705880bc } from "@ember/template-compiler";
const FKLabel = template_4d6ad0ae1a074db597970b26705880bc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
