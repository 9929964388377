import { template as template_e3bc359359514d91af94d517507fd366 } from "@ember/template-compiler";
const WelcomeHeader = template_e3bc359359514d91af94d517507fd366(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
