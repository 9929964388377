import { template as template_4537a58a35154c36bb038936126f84fe } from "@ember/template-compiler";
import { LinkTo } from "@ember/routing";
import { or } from "truth-helpers";
import dIcon from "discourse-common/helpers/d-icon";
import { i18n } from "discourse-i18n";
export default template_4537a58a35154c36bb038936126f84fe(`
  <LinkTo class="btn btn-flat back-button" @route={{@route}}>
    {{dIcon "chevron-left"}}
    {{i18n (or @label "back_button")}}
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
